<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <task-form :task="task" :own="own" :accessible="accessible">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </task-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../../components/elements/callback-button.vue";
import TaskForm from "../../../../components/forms/tasks/task-form.vue";

export default {
    props: {
        own: {
            type: Boolean,
            default: false
        },
        accessible: {
            type: Boolean,
            default: false
        }
    },

    components: {TaskForm, CallbackButton},

    computed: {
        ...mapGetters({
            task: 'task/item'
        }),

        title: function () {
            return this.task.id ? this.$tc('tasks::tasks.task', 2).ucFirst() + ' / ' + (this.task.translation_key ? this.$t(this.task.translation_key).ucFirst() :this.task.name) : this.$t('base.create_new_item', {item: this.$tc('tasks::tasks.task', 1)}).ucFirst()
        },

        link: function () {
            const translationKey = 'routes.' + (this.accessible ? '/tasks/accessible-tasks' : (this.own ? '/tasks/my-tasks' : '/master-data/tasks/tasks' ))
            return {path: this.$t(translationKey)}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('task/show', {id: this.$route.params.id, query: { with: ['files', 'users', 'contributors', 'responsibles', 'comments'] }})
        }
    },

    unmounted() {
        this.$store.dispatch('task/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
